import React from "react"

const BlogHeader = ({ header, subHeader, background }) => {
  return (
    <section>
      <div
        className="blog-header"
        style={{
          backgroundImage:
            "url(" + background + ")",
          backgroundRepeat: "repeat-x",
        }}
      >
        <div className='header-text'>
          <h1 className="title">{header}</h1>
          <p className="text">
            {subHeader}
          </p>
          </div>
        <div className='gradient'></div>
      </div>

    </section>
  )
}
export default BlogHeader
